class Badge {
    identifier: string;
    name: string;
    path: string;
    count: number;

    showPopup: boolean;

    openBadgePopup() {
        this.showPopup = true;
    }

    closeBadgePopup() {
        this.showPopup = false;
    }
}

window.app.component('badge', {
    template: require('scripts/components/profile/gamification/badge/badge.html'),
    controller: [Badge],
    bindings: {
        identifier: '<',
        name: '<',
        path: '<',
        count: '<'
    }
});