class Gamification {
    $scope: any;
    $translate: any;
    api: any;
    authService: any;
    databaseService: any;
    loaderFactory: any;
    appFactory: any;
    notification: any;

    leaderboardBy: any = {
        label: 'badges.leaderboardByApprovedWorked',
        value: 'approved_working_periods',
        dir: false
    }
    filter: string;
    myScore: any;
    data: UIData = {
        myScore: {
            workedHours: 0,
            workedPeriods: 0,
            averageDelay: 0,
            currentStreak: 0
        },
        seniority: [],
        stamina: [],
        streak: [],
        ranking: []
    }
    options = [
        {
            label: 'badges.leaderboardByApprovedWorked',
            value: 'approved_working_periods',
            dir: false
        },
        {
            label: 'badges.leaderboardByApprovedWorkingPeriods',
            value: 'approved_worked_seconds',
            dir: false
        },
        {
            label: 'badges.leaderboardByAverageReportDelay',
            value: 'average_report_delay_seconds',
            dir: true
        }
    ]

    constructor($scope: any, $translate: any, API: any, AuthService: any, DatabaseService: any, LoaderFactory: any, AppFactory: any, Notification: any) {
        Object.defineProperties(this, {
            $scope: {value: $scope},
            $translate: {value: $translate},
            api: {value: API},
            authService: {value: AuthService},
            databaseService: {value: DatabaseService},
            loaderFactory: {value: LoaderFactory},
            appFactory: {value: AppFactory},
            notification: {value: Notification},
        });

        Promise.all([
            this.loadMyScore(),
            this.loadRewards(),
            this.loadScores()
        ]).then(() => {
            this.loaderFactory.hide();
        }).catch((error: any) => {
            this.loaderFactory.hide();
            this.notification.alert(error);
        });
    }

    typeChanged(type: any) {
        this.leaderboardBy = type;
        this.refreshLeaderboard();
    }

    refreshLeaderboard() {
        this.loaderFactory.show();
        this.loadScores().then(() => {
            this.loaderFactory.hide();
        }).catch((error: any) => {
            this.loaderFactory.hide();
            this.notification.alert(error);
        });
    }

    private async loadMyScore(): Promise<any> {
        return this.api.loadMyScore().then((res: any) => {
            this.myScore = res;
            this.data.myScore = {
                workedHours: res && res.approved_worked_seconds && Math.round(res.approved_worked_seconds / 60 / 60) || 0,
                workedPeriods: res && res.approved_working_periods || 0,
                averageDelay: res && res.average_report_delay_seconds && Math.round(res.average_report_delay_seconds / 60 / 60 / 24) || 0,
                currentStreak: res && res.current_streak || 0,
            }
        })
    }

    private async loadRewards(): Promise<any> {
        return this.api.loadRewards().then((res: any) => {
            this.data.seniority = []
            this.data.stamina = []
            this.data.streak = []
            for (const it of res.rewards) {
                it?.badge?.badge_type === 'seniority' && this.data.seniority.push(it);
                it?.badge?.badge_type === 'stamina' && this.data.stamina.push(it);
                it?.badge?.badge_type === 'streak' && this.data.streak.push(it);
            }
        })
    }

    private async loadScores(): Promise<any> {
        const column = this.leaderboardBy.value;
        return this.api.loadScores(column, this.leaderboardBy.dir, 1).then((res: any) => {
            const ranking: Rank[] = []
            for (const rank of res.scores) {
                let value: number;
                switch (column) {
                    case "approved_working_periods":
                        value = rank.approved_working_periods;
                        break;
                    case "approved_worked_seconds":
                        value = Math.round(rank.approved_worked_seconds / 60 / 60);
                        break;
                    case "average_report_delay_seconds":
                        value = Math.round(rank.average_report_delay_seconds / 60 / 60 / 24);
                        break;
                    default:
                        value = rank.approved_working_periods;
                }
                let user = (rank.external_employee?.first_name ?? "") + " " + (rank.external_employee?.last_name ?? "");
                ranking.push({
                    pos: rank.user_rank,
                    user: user && user != " " ? user : "-",
                    value: value,
                });
            }

            const user = (this.authService.authorizedUser?.first_name ?? "") + " " + (this.authService.authorizedUser?.name ?? "");
            if (!ranking.find(it => it.user === user)) {
                let score = this.myScore[column];
                if (column === "approved_worked_seconds") {
                    score = Math.round(score / 60 / 60)
                } else if (column === "average_report_delay_seconds") {
                    score = Math.round(score / 60 / 60 / 24)
                }
                ranking.push({
                    pos: this.myScore.ranks[column],
                    user: user && user != " " ? user : "-",
                    value: score || 0,
                });
            }
            this.data.ranking = ranking;
        });
    }
}

interface UIData {
    myScore: Score
    seniority: Reward[];
    stamina: Reward[];
    streak: Reward[];
    ranking: Rank[];
}

interface Score {
    workedHours: number;
    workedPeriods: number;
    averageDelay: number;
    currentStreak: number;
}

interface Reward {
    badge_count: number;
    badge: any;
}

interface Rank {
    pos: number;
    user: string;
    value: number;
}

window.app.component('gamification', {
    template: require('scripts/components/profile/gamification/gamification.html'),
    controller: ["$scope", '$translate', 'API', 'AuthService', 'DatabaseService', 'LoaderFactory', 'AppFactory', 'Notification', Gamification]
});