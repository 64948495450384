class Profile {

    $scope: any;
    $location: any;
    $translate: any;
    appFactory: any;
    authService: any;
    state: string = null;

    constructor($scope: any, $location: any, $translate: any, AuthService: any, AppFactory: any) {
        Object.defineProperties(this, {
            $scope: {value: $scope},
            $location: {value: $location},
            $translate: {value: $translate},
            authService: {value: AuthService},
            appFactory: {value: AppFactory},
        });
    }

    $onInit() {
        const tab = this.$location.search()["segment"] ?? "profile";
        this.changeTab(tab);
    }

    changeTab(state: string): void {
        if (state === 'devices' && !this.manageUserDevices()) {
            this.changeTab('profile');
            return;
        }
        if (this.state === state) {
            return;
        }
        this.changeTitle(state).then(() => {
            this.state = state;
            this.$location.search("segment", state);
            this.$scope.$apply();
        });
    }

    manageUserDevices = () => {
        return this.authService.getAuthorizedUser() && this.authService.getAuthorizedUser().features['manage_user_devices'];
    }

    private async changeTitle(state: string): Promise<any> {
        let title: string;
        switch (state) {
            case 'profile':
                title = "profile.tabProfile";
                break;
            case 'devices':
                title = "profile.tabDevices";
                break;
            case 'badges':
                title = "profile.tabBadges";
                break;
        }
        return this.$translate([title]).then(t => this.appFactory.setTitle(t[title]));
    }
}

window.app.component('profile', {
    template: require('scripts/components/profile/profile.html'),
    controller: ["$scope", '$location', '$translate', 'AuthService', 'AppFactory', Profile]
});
