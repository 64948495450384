class UserProfile {

    $scope: any;
    authService: any;

    name: string;
    phoneNumber: string;
    personalNumber: string;
    branch: string;

    constructor($scope: any, AuthService: any) {
        Object.defineProperties(this, {
            $scope: {value: $scope},
            authService: {value: AuthService},
        });
    }

    $onInit() {
        const user = this.authService.getAuthorizedUser();
        this.name = user?.first_name + ' ' + user?.name;
        this.phoneNumber = user?.phone_number;
        this.personalNumber = user?.personal_number;
        this.branch = `${user?.location?.city} (${user?.location?.identifier})`

    }

    changePassword() {
        //TODO: implement https://daydreamunicorn.atlassian.net/browse/TEMP-15734
    }

    enable2FA() {
        //TODO: implement https://daydreamunicorn.atlassian.net/browse/TEMP-15593
    }

    changeAvatar() {
        //TODO: implement
    }
}

window.app.component('userProfile', {
    template: require('scripts/components/profile/user-profile/user-profile.html'),
    controller: ["$scope", 'AuthService', UserProfile]
});
