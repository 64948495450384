class Devices {

    api: any;
    loaderFactory: any;
    notification: any;

    columns: any[] = [
        {label: 'devices.lastConnectedAt', sort: 'last_connected_at'},
    ];
    page: number = 1;
    totalCount: number = 0;
    column: any = "last_connected_at";
    reverse: boolean = false;
    devices: any[];

    constructor(API: any, LoaderFactory: any, Notification: any) {
        Object.defineProperties(this, {
            api: {value: API},
            loaderFactory: {value: LoaderFactory},
            notification: {value: Notification},
        });
        this.loadDevices();
    }

    paginationCallback(page: number): void {
        this.loadDevices(page);
    }

    sortCallback(column, reverse) {
        this.column = column;
        this.reverse = reverse;
        this.loadDevices(null);
    }

    lockDevice(deviceId: string): void {
        return this.notification.confirm_p({
            title: 'devices.lockDevice',
            desc: 'devices.confirmLockDevice',
            cancelButton: 'cancel',
            button: 'yes'
        }).then((res: number) => {
            if (res === 2) {
                this.lockOrUnlockDevice(deviceId, true);
            }
        });

    }

    unlockDevice(deviceId: string): void {
        return this.notification.confirm_p({
            title: 'devices.unlockDevice',
            desc: 'devices.confirmUnlockDevice',
            cancelButton: 'cancel',
            button: 'yes'
        }).then((res: number) => {
            if (res === 2) {
                this.lockOrUnlockDevice(deviceId, false);
            }
        });
    }

    isDeviceLocked(device: any): boolean {
        return device && device.locked_by_user_at
    }

    isToday(time: string): boolean {
        const current = new Date();
        const date = new Date(time)
        return current.getDate() === date.getDate() &&
            current.getMonth() === date.getMonth() &&
            current.getFullYear() === date.getFullYear();
    }

    getDeviceType(device: any): string {
        if (device.platform === 'android')      return 'icon-smartphone';
        if (device.model.includes('iPhone'))    return 'icon-smartphone';
        if (device.model.includes('iPad'))      return 'icon-tablet';
        if (device.model.includes('Macintosh')) return 'icon-computer';
        return 'icon-computer';
    }

    private lockOrUnlockDevice(deviceId: string, lock: boolean): void {
        this.loaderFactory.show();
        this.api.lockDevice(deviceId, lock).then(() => {
            this.loaderFactory.hide();
            this.loadDevices();
        }).catch((error: any) => {
            this.loaderFactory.hide();
            this.notification.alert(error);
        });
    }

    private loadDevices(page = null) {
        if (!page) page = this.page;
        this.loaderFactory.show();
        this.api.loadDevices(this.column, this.reverse, page).then((res: any) => {
            this.loaderFactory.hide();
            this.page = page;
            if (res) {
                this.totalCount = res.meta.paging.total_pages;
                this.devices = res.user_devices;
            }
        }).catch((error: any) => {
            this.loaderFactory.hide();
            this.notification.alert(error);
        });
    }

}

window.app.component('devices', {
    template: require('scripts/components/profile/devices/devices.html'),
    controller: ['API', 'LoaderFactory', 'Notification', Devices]
});
