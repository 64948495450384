class Rewards {

    title: string;
    rewards: any[];
    isOpened: boolean = false;

    openClose() {
        this.isOpened = !this.isOpened;
    }
}

window.app.component('rewards', {
    template: require('scripts/components/profile/gamification/rewards/rewards.html'),
    controller: [Rewards],
    bindings: {
        title: '<',
        rewards: '<'
    }
});